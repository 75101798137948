import React from "react"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils

// Components
import Section from "components/case-study-section/"
import breakpoints from "utils/breakpoints"

const StyledSection = styled(Section)`
  ${breakpoints.medium`
    margin-bottom: 40px;
  `}
  .animation-wrapper {
    margin-top: 60px;
  }
`

const Challange = () => {
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Combining a techy, game-like feel with the seriousness and
              professionalism required to build trust in users
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              The team came to us with just a few brand assets to work with: A
              logo and simple landing page, Github and Discord servers, and
              contacts for game developers who successfully built and launched
              games using Hathora to interview about their experiences. The next
              step was our job: Building Hathora a brand that reflected them as
              the knowledgeable, trustworthy experts that they are while keeping
              it exciting.
            </Trans>
            <br />
            <br />
            <Trans>
              Possibly the biggest challenge we faced when taking on this
              project was the tight timeline leading up to the launch date for
              the website–Less than two months from when we began the branding
              process to when the site would be launched. We knew that meant we
              had to define Hathora’s brand as quickly as possible, without
              losing quality and ensuring our client was satisfied at every
              step. We achieved it with a combination of agile methodologies and
              stellar communication between teams.
            </Trans>
          </p>
        </div>
      </div>
    </StyledSection>
  )
}

export default Challange
