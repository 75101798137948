import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  .image-example {
    background-color: black;
    padding: 60px 26px 30px 26px;
    margin-top: 40px;

    ${breakpoint.medium`
      padding: 80px 70px 40px 70px;
      margin-top: 80px;
    `}

    .row {
      position: relative !important;
      .blur {
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        bottom: 44.25%;
        background: #af64ee;
        mix-blend-mode: normal;
        opacity: 0.8;
        filter: blur(292.888px);
        transform: matrix(-0.26, 0.97, -0.97, -0.26, 0, 0);
        width: 450px;
        height: 300px;
        overflow: hidden;

        ${breakpoint.medium`
          width: 540px;
          height: 978px;
          right: 25%;
          left: 25%;
          top: 0;
          bottom: 0;
        `}
      }
    }
  }
`

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;
  overflow: hidden;

  ${breakpoint.medium`
    margin-top: 60px;
    padding-bottom: 100px;
  `}

  .image {
    width: 100%;
    max-width: 1380px;
    display: block;
    margin-bottom: 16px;
    margin-left: auto;

    &:nth-child(even) {
      margin-right: -40px;

      ${breakpoint.medium`
        margin-right: -80px;
      `}
    }

    ${breakpoint.medium`
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const TheSolution2 = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/hathora/hathora-carousel-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/hathora/hathora-carousel-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageThree: file(
        relativePath: { eq: "case-studies/hathora/hathora-concept-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageFour: file(
        relativePath: { eq: "case-studies/hathora/hathora-concept-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageFive: file(
        relativePath: { eq: "case-studies/hathora/hathora-concept-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSix: file(
        relativePath: { eq: "case-studies/hathora/hathora-concept-6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100, maxHeight: 229) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSolution1: file(
        relativePath: { eq: "case-studies/hathora/image-1-hathora.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSolution2: file(
        relativePath: { eq: "case-studies/hathora/image-2-hathora.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSolution3: file(
        relativePath: { eq: "case-studies/hathora/image-3-hathora.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSolution4: file(
        relativePath: { eq: "case-studies/hathora/mobile-image-hathora.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSolution5: file(
        relativePath: { eq: "case-studies/hathora/illus-hathora-cs.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 448, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--white">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <div />
          </div>
        </div>
        <div className="section__content">
          <p className="title">
            <Trans>
              2. A moodboard phase to pick which references and visual themes
              aligned most closely with the Hathora team’s vision
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
        {/* <div className="image" data-aos="fade-left" data-aos-duration="600">
            <Img
              fluid={data.imageThree.childImageSharp.fluid}
              alt="Illustration System"
              style={{ width: "100%" }}
            />
          </div> */}
        {/* <div className="d-flex justify-content-between ml-5">
          <div className="image" data-aos="fade-right" data-aos-duration="600">
            <Img
              fluid={data.imageFour.childImageSharp.fluid}
              alt="Illustration System"
              style={{ width: "100%" }}
            />
          </div>
          <div className="image" data-aos="fade-right" data-aos-duration="600">
            <Img
              fluid={data.imageFive.childImageSharp.fluid}
              alt="Illustration System"
              style={{ width: "100%" }}
            />
          </div>
          <div className="image" data-aos="fade-right" data-aos-duration="600">
            <Img
              fluid={data.imageSix.childImageSharp.fluid}
              alt="Illustration System"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div> */}
      </ImageWrapper>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <div />
          </div>
        </div>
        <div className="section__content">
          <p className="title">
            <Trans>
              3. Brand design rounds to pick the final colors, logo, typography,
              and graphic elements
            </Trans>
          </p>
          <p>
            <Trans>
              The brand elements created during this process included an updated
              version of the logo, which was formerly a flaming hammer. Hathora
              means hammer in Hindi, and represents the ease of game building on
              the platform, so it was crucial that we maintained this element.
              The new logo features a sleeker hammer that has a gradient of
              color across it, representing the power, energy and agility that
              Hathora’s tools hold.
            </Trans>
            <br />
            <br />
            <Trans>
              The typographies were selected to give a tech-like feel and
              reflect the typography used in code, while the new color palette
              added fresh pops of color to the dark mode theme that developers
              know and love.
            </Trans>
            <br />
            <br />
            <Trans>
              We also began working on the animated illustrations that would
              accompany the website and all Hathora-branded materials, which are
              made up predominantly of connected lines with color gradients
              running through them–a representation of infrastructure, agile
              data exchange and global connection when playing multiplayer
              games. Our goal with these illustrations was to strike a balance
              between abstract concepts and the actual infrastructure that keeps
              cloud-based games up and running.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-example">
        <div className="row align-items-center px-0">
          <div className="blur" />
          <div className="col-12 col-md-4 mb-5">
            <Img
              fluid={data.imageSolution1.childImageSharp.fluid}
              alt="Illustration System"
              style={{ maxWidth: "320px" }}
            />
          </div>
          <div className="col-12 col-md-4 mb-5">
            <Img
              fluid={data.imageSolution2.childImageSharp.fluid}
              alt="Illustration System"
              style={{ maxWidth: "320px" }}
            />
          </div>
          <div className="col-12 col-md-4 mb-5">
            <Img
              fluid={data.imageSolution3.childImageSharp.fluid}
              alt="Illustration System"
            />
          </div>
          <div className="col-12 col-md-7 mb-5">
            <Img
              fluid={data.imageSolution4.childImageSharp.fluid}
              alt="Illustration System"
              style={{ maxWidth: "717px" }}
            />
          </div>
          <div className="col-12 col-md-5 mb-5">
            <Img
              fluid={data.imageSolution5.childImageSharp.fluid}
              alt="Illustration System"
              style={{ maxWidth: "541px" }}
            />
          </div>
        </div>
      </div>
    </StyledSection>
  )
}

export default TheSolution2
