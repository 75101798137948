import React, { useEffect } from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import AOS from "aos"
import "aos/dist/aos.css"

// Layout
import Layout from "layouts/layout-primary"

// Components
import ContextConsumer from "components/context/"
import Seo from "components/seo/"
import Cta from "components/case-study-call-to-action/"

// OpenGraph
import OpenGraph from "assets/images/case-studies/hathora/open-graph.png"

// Sections
import Hero from "sections/case-studies/hathora/hero"
import TheProject from "sections/case-studies/hathora/the-project"
import TheChallenge from "sections/case-studies/hathora/the-challenge"
import TheSolution from "sections/case-studies/hathora/the-solution"
import TheSolution2 from "sections/case-studies/hathora/the-solution-2"
import TheResult from "sections/case-studies/hathora/the-result"

// Next Projects
import NextProject from "components/case-study-next-project/"
import NextProjectHero from "sections/case-studies/kapital/hero"

const Hathora = props => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  }, [])

  const { t } = useTranslation()

  return (
    <Layout menuColor={colors.supernova}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (!data.darkMenu) {
            set({ darkMenu: true })
          }
          if (data.showFooter) {
            set({ showFooter: false })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Indicius Case Study - Indicius Design Agency")}
        description={t(
          "Rebranding Indicius. A case study about revitalizing our visual brand and aligning it to our mission as a human-centered design agency."
        )}
        image={OpenGraph}
      />
      <Hero />
      <TheProject />
      <TheChallenge />
      <TheSolution />
      <TheSolution2 />
      <TheResult />
      <Cta
        title={t(
          "Do you have a revolutionary idea for a digital platform, app, or service that you’re ready to see come to life?"
        )}
        subtitle={t("We have our cursors at the ready.")}
      />
      <NextProject url="/work/kapital" nextProjectHero={<NextProjectHero />} />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Hathora
