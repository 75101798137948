import React from "react"

// Components
import LottieObserved from "components/lottie-observed/"

// Libraries
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"
import breakpoint from "utils/breakpoints/"

// // Icons
// import Game from "assets/icons/case-studies/hathora/icon-estrellas.inline.svg"
// import Drop from "assets/icons/case-studies/hathora/icon-game.inline.svg"
// import Constellations from "assets/icons/case-studies/hathora/icon-gota.inline.svg"

// Animations
import Animation1 from "assets/animations/case-studies/hathora/our-platform-animation-1.json"
import Animation2 from "assets/animations/case-studies/hathora/our-platform-animation-2.json"
import Animation3 from "assets/animations/case-studies/hathora/our-platform-animation-3.json"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  overflow-y: hidden;

  .section__content {
    .cs__paragraph {
      margin-botton: 40px;
    }
  }

  .image-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    ${breakpoint.small`
      flex-direction: row;
      justify-content: space-around;
      padding: 30px 0;
    `}

    ${breakpoint.medium`
        margin-bottom: 0;
        margin-top: 80px;
    `}

    .icons {
      margin-bottom: 40px;
    }
  }
`

const TheProject = () => {
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The project</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <p className="cs__paragraph">
            <a href="https://hathora.dev">Hathora</a>
            <Trans>
              {" "}
              is a serverless cloud platform for multiplayer games. Built by
              infrastructure engineers from Palantir, Databricks, and AWS,
              Hathora provides the tools necessary to build, launch, and scale
              multiplayer games without downtime. Our project with Hathora began
              with a complete, ground-up branding process that would later be
              applied to their new website and present Hathora to the world as
              the #1 solution for game studios and developers.
            </Trans>
          </p>
        </div>
      </div>

      <div className="image-wrapper">
        <div className="icons">
          <LottieObserved animation={Animation2} loop />
        </div>
        <div className="icons">
          <LottieObserved animation={Animation1} loop />
        </div>
        <div className="">
          <LottieObserved animation={Animation3} loop />
        </div>
      </div>
    </StyledSection>
  )
}

export default TheProject
