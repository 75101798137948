import React from "react"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints"

// Styled
import Section from "components/case-study-section"
import styled from "styled-components"
import breakpoints from "utils/breakpoints"

const StyledSection = styled(Section)`
  padding-bottom: 0px !important;

  .image-example {
    background-color: black;
    padding: 20px;

    ${breakpoint.medium`
      padding: 80px 70px 40px 70px;
    `}
  }
`

const StyledImageContainer = styled.div`
  background-color: black;
  padding: 20px 0 70px 0;
  position: relative;
  margin-top: 40px;

  ${breakpoints.medium`
    margin-top: 80px;
  `}

  .blur {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 44.25%;
    background: #af64ee;
    mix-blend-mode: normal;
    opacity: 0.8;
    filter: blur(292.888px);
    transform: matrix(-0.26, 0.97, -0.97, -0.26, 0, 0);
    width: 450px;
    height: 300px;
    display: none;

    ${breakpoint.medium`
      display: block;
      width: 540px;
      height: 978px;
      right: 25%;
      left: 25%;
      top: 0;
      bottom: 0;
    `}
  }

  .row {
    --bs-gutter-x: 0px;
  }

  .col-12 {
    padding: 0;
  }
`

const TheResult = () => {
  const data = useStaticQuery(graphql`
    query {
      imageResult: file(
        relativePath: { eq: "case-studies/hathora/the-result-image.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="--ejemplo">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A Brandbook: a manual that lays out all of Hathora’s essential
              brand elements along with guidelines for how to use them
            </Trans>
          </h3>

          <br />

          <p>
            <Trans>
              This brandbook meant that not only could the branding team hand
              their work over to the UX/UI team to build the new website, but it
              also sets Hathora up to be self-sufficient in the future. Whenever
              they want to create new designs or marketing materials, they’ll
              have the brandbook at hand with all the assets they need.
            </Trans>
            <br />
            <br />
            <Trans>
              Hathora’s brandbook included the new logo in all its variations,
              typography with usage guidelines, color system and guidelines,
              illustrations, and examples of applications that combine all the
              brand elements in different compositions on the web and in ads.
            </Trans>
            <br />
            <Trans>
              Now Hathora has a cohesive, professional brand to step out into
              the gaming world as the trailblazers they are.
            </Trans>
          </p>
        </div>
      </div>{" "}
      <StyledImageContainer>
        <div className="image-container">
          <div className="blur" />
          <div className="row justify-content-center">
            <div className="col-12 col-md-8">
              <div>
                <Img
                  fluid={data.imageResult.childImageSharp.fluid}
                  alt="Illustration System"
                  style={{ maxWidth: "1052px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledImageContainer>
    </StyledSection>
  )
}

export default TheResult
