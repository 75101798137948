import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Components
import CaseStudyHero from "components/case-study-hero/"
import FeaturedImage from "./featured-image"

const Hero = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <CaseStudyHero
        title={t(
          "Imagining a sophisticated, tech-focused brand for the cloud-based solution to multiplayer games"
        )}
        details={[t("Hathora"), "New York, USA", "2022 - 2023"]}
        tags={[
          t("Brand Narrative Workshop"),
          t("Visual Branding"),
          t("BrandBook"),
          t("Illustration"),
          t("Motion UI"),
        ]}
        backgroundColor={colors.cream}
        color={colors.midnight}
        bulletColor={colors.midnight}
        width={"832px"}
      />
      <FeaturedImage />
    </React.Fragment>
  )
}

export default Hero
