import React from "react"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import StyledSection from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;
  overflow: hidden;

  ${breakpoint.medium`
    margin-top: 60px;
    padding-bottom: 100px;
  `}

  .image {
    width: 100%;
    max-width: 1380px;
    display: block;
    margin-bottom: 16px;
    margin-left: auto;

    &:nth-child(even) {
      margin-right: -40px;

      ${breakpoint.medium`
        margin-right: -80px;
      `}
    }

    ${breakpoint.medium`
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const TheSolution = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/hathora/hathora-slider-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/hathora/hathora-slider-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection className="bg--cream">
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The solution</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Hathora’s branding process left no stone unturned. It included:
            </Trans>
          </h3>
          <br />
          <p className="title">
            <Trans>
              1. Indicius’s custom Brand Narrative Workshop to define the
              brand’s purpose and personality
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <div className="image" data-aos="fade-left" data-aos-duration="600">
          <Img
            fluid={data.imageOne.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>

        <div className="image" data-aos="fade-right" data-aos-duration="600">
          <Img
            fluid={data.imageTwo.childImageSharp.fluid}
            alt="Illustration System"
            style={{ width: "100%" }}
          />
        </div>
      </ImageWrapper>
    </StyledSection>
  )
}

export default TheSolution
